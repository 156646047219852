@import "../main/abstracts/index";

.search {
    .page__header & {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 200px;
    }
}
body .page__header .search{
       height: $header__height--mobile;

       @include media-breakpoint-up(lg) {
        height: $header__height--unpinned;
    }

    @include media-breakpoint-up(xxl) {
        height: $header__height;
        transition: height .3s ease-in-out;
    }

    html.headroom--unpinned & {
        @include media-breakpoint-up(xxl) {
            height: $header__height--unpinned;
        }
    }
}
html.js .main-navigation__item  .main-navigation__link>span[aria-hidden]{
    background-image: url('/assets/website/img/arrow.svg');
    @include recolor($color__primary)
}
.off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__inner{
    padding-left: 75px;
    padding-right: 75px;
    @include media-breakpoint-down(sm) {
        padding-left: 20px;
        padding-right: 20px;
    }

}
.search--banner{
    position: absolute;
    left: 50%;
    bottom: 180px;
    transform: translateX(-50%);
    z-index: 3;
    width: 100%;
    max-width: 650px;
    @include media-breakpoint-down(md) {
        bottom: unset;
        top: 150px; 
    }
    .search{
        height: auto!important;
        width: 100%;
        .search__preview{
            width: 100%;
            max-width: 650px;
            padding: 0 20px;

        }
    }
}
.search_header{
    margin-left: 50px;
}
body .page__header{
    >.container-fluid.ps-0{
        padding-right: 260px;
        @include media-breakpoint-down(lg) {
            padding-right: 0;
        }
    }
}

.headroom--above-the-fold body[data-template="home"] .page__header .search_header .search .search__preview{
    display: none;
}
body[data-template="home"].c-gufi-preview-wrapper-active{
    .page__header>.container-fluid,
    .page__header:before{
        z-index: -1;
    }
}
.c-gufi-preview-wrapper-active{
    .page__main{
        position: relative;
        z-index: 0;
    }
    .page__header{
        position: relative;
        z-index: 1;
    }
}